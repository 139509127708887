export default function Skills() {
  return (
    <section id="skills" className="section">
      <div className="container">
        <div className="row wave-bg">
          <div className="zigzag wow slideInLeft">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              version="1.1"
              x="0px"
              y="0px"
              viewBox="0 0 105 20"
              xmlSpace="preserve"
            >
              <g>
                <rect className="st0" width="105" height="20" />
                <g>
                  <polyline
                    className="st1"
                    points="2.5,5 16.8,15 31.1,5 45.3,15 59.6,5 73.9,15 88.2,5 102.5,15   "
                  />
                </g>
              </g>
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              version="1.1"
              x="0px"
              y="0px"
              viewBox="0 0 105 20"
              xmlSpace="preserve"
            >
              <g>
                <rect className="st0" width="105" height="20" />
                <g>
                  <polyline
                    className="st1"
                    points="2.5,5 16.8,15 31.1,5 45.3,15 59.6,5 73.9,15 88.2,5 102.5,15   "
                  />
                </g>
              </g>
            </svg>
          </div>
          <div className="col-md-4 wow slideInLeft">
            <div className="section-sidebar">
              <h2>
                <span className="point">Skills</span>
              </h2>
              <div className="opacity-box">
                <p>
                  I am inspired by creating great work with people who are as
                  passionate as I am about building something awesome.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-8">
            <div className="row">
              <div className="col-md-6 wow zoomIn">
                <div className="advantages-box">
                  <h4>Web Development</h4>
                  <div className="opacity-box">
                    <p>
                      Throughout my professional journey, I have cultivated a
                      diverse skill set in various programming languages and
                      tools.
                      <br />
                      <br />
                      With a passion for continuous learning, I have embraced
                      the challenges and opportunities presented by different
                      technologies, allowing me to adapt and excel in a rapidly
                      evolving landscape.
                      <br />
                      <br />
                      some of those programming languages and technologies are
                      but not limit to:
                      <br />
                      <br />
                    </p>
                    <p className="experience-tools">
                      Javascript, Typescript, Node.js, Reactjs, Vuejs, Python,
                      Java, MongoDB, Amazon Web Services, Serverless, lambda,
                      SQS, SNS, S3, Dynamodb Redis, PostgreSQL, Mysql, Docker,
                      Kubernetes, and many more...
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 wow zoomIn">
                <hr className="mobile-only" />
                <div className="advantages-box">
                  <h4>Software Solution Architect</h4>
                  <div className="opacity-box">
                    <p>
                      My experience as a Software Solution Architect spans
                      several years, during which I have demonstrated a strong
                      ability to design and implement comprehensive software
                      solutions that address complex business challenges.
                      <br />
                      <br />
                      With a deep understanding of diverse technologies and a
                      keen eye for system integration, I have successfully
                      crafted architectures that optimize performance,
                      scalability, and security.
                      <br />
                      <br />
                      By collaborating closely with stakeholders, I have
                      gathered requirements, analyzed business needs, and
                      translated them into robust architectural designs that
                      align with organizational goals.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 wow zoomIn" style={{ clear: "both" }}>
                <hr />
                <div className="advantages-box">
                  <h4>Amazon Web Services</h4>
                  <div className="opacity-box">
                    <p>
                      Throughout my journey with AWS, I demonstrated a deep
                      understanding of cloud computing principles, leveraging
                      AWS services to optimize costs, improve efficiency, and
                      drive innovation.
                      <br />
                      <br />
                      They have been involved in various projects, ranging from
                      building robust web applications and managing high-traffic
                      websites to implementing data analytics solutions and
                      deploying machine learning models.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 wow zoomIn">
                <hr />
                <div className="advantages-box">
                  <h4>Team leading & managing</h4>
                  <div className="opacity-box">
                    <p>
                      Throughout my career, I have excelled in leading and
                      managing cross-functional software engineering teams,
                      bringing together diverse skill sets and expertise to
                      drive successful project outcomes.
                      <br />
                      <br />
                      With a strong focus on collaboration and effective
                      communication, I have fostered a cohesive and motivated
                      team environment where individuals thrive and contribute
                      their best.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
