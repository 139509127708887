export const categories = [
  {
    title: "All projects",
    key: "all",
  },
  {
    title: "Crypto",
    key: ".crypto",
  },
  {
    title: "Fintech",
    key: ".fintech",
  },
  {
    title: "Micro services",
    key: ".microservices",
  },
];

export const projects = [
  {
    key: "btc-gw",
    title: "Bitcoin payment gateway",
    img: "media/portfolio-images/btc-payment-gateway.jpeg",
    date: "April, 2019",
    categories: "crypto fintech",
    stack:
      "Blockchain, Rest APIs, AWS, Nodejs, Typescript, Docker, Kubernetes, mysql and many more...",
    description: `
        As part of my professional journey, I undertook a groundbreaking project focused on the development of a Bitcoin payment gateway.
        This pioneering initiative aimed to revolutionize the way businesses transact in the digital realm. By harnessing the power of blockchain technology,
        I spearheaded the design and implementation of a secure and efficient payment infrastructure that seamlessly integrated Bitcoin transactions into various online platforms.
        Through my unwavering commitment to innovation and meticulous attention to detail, I successfully delivered a robust and user-friendly solution that empowered businesses to embrace the future of digital payments.      
        `,
    website: "https://edenmoney.com",
    images: ["media/portfolio-images/btc-payment-gateway.jpeg"],
  },
  {
    key: "rw-app",
    title: "Receipt Wallet App",
    img: "media/portfolio-images/receipt-wallet-app-1.png",
    date: "June, 2023",
    categories: "fintech",
    stack: "OCR, AI, ML, Nodejs, React, AWS, Serverless, RDS and many more...",
    description: `
        Say Goodbye to Paper Receipts with Receipt Wallet.
        <br>
        Receipt Wallet is a digital receipt mobile app used to store digital copies of paper receipts on a mobile device.        
        <br>
        <br>

        I assumed the responsibility of defining the software architecture while effectively leading a cross-functional team.
        `,
    website: "https://receiptwallet.app",
    images: ["media/portfolio-images/receipt-wallet-app-2.png"],
  },
  {
    key: "sn-monolith-to-micro",
    title: "Migrating monolith app into micro service architecture",
    img: "media/portfolio-images/monolith-to-micro.png",
    date: "May 2018 - Feb, 2021",
    categories: "microservices",
    stack:
      "Nodejs, React, Mongodb, RabbitMQ, REST APIs, AWS, Serverless, RDS and many more...",
    description: `
        Migrating a monolithic application into a microservices architecture is a complex undertaking that involves breaking down a large, monolithic application into smaller, independent services. 
        <br>
        <br>
        Here's a brief overview of the experience I encountered during such a migration:
        <br>
        <div>
          <ol>
              <li>Planning and Analysis:</li>
                  <ul>
                      <li>Understand the existing monolithic application: Analyze its structure, dependencies, and functionalities.</li>
                      <li>Identify the potential benefits: Determine why you want to migrate to a microservices architecture and what improvements it can bring.</li>
                      <li>Define the microservices boundaries: Identify the key components or functionalities that can be decoupled and separated into individual services.</li>
                  </ul>            
              <li>Designing Microservices:
                  <ul>
                      <li>Determine service boundaries: Identify the specific services that will be created and define their responsibilities.</li>
                      <li>Define communication mechanisms: Decide how the microservices will communicate with each other, such as using synchronous or asynchronous protocols like REST, messaging queues, or event-driven architectures.</li>
                      <li>Plan for data management: Determine how data will be shared and managed between services, such as using dedicated databases per service or employing a shared data layer.</li>
                  </ul>
              </li>
              <li>Breaking Down the Monolith:
                  <ul>
                      <li>Extracting services: Identify cohesive modules or functionalities within the monolithic application that can be separated and transformed into standalone services.</li>
                      <li>Re-architecting and refactoring: Modify the codebase to align with the microservices architecture, including restructuring the code, removing shared dependencies, and establishing clear interfaces between services.</li>
                      <li>Deploying initial services: Gradually deploy the extracted services while maintaining compatibility with the existing monolith.</li>
                  </ul>
              </li>
              <li>Infrastructure and Deployment:
                  <ul>
                      <li>Containerization: Consider using containerization technologies like Docker to package and deploy individual microservices.</li>
                      <li>Orchestration: Utilize container orchestration platforms such as Kubernetes to manage and scale the microservices deployment.</li>
                      <li>Deployment strategy: Determine the rollout strategy, which can involve gradual migration, parallel operation, or a complete cutover from the monolithic application.</li>
                  </ul>
              </li>
              <li>Monitoring and Operations</li>
              <li>Iterative Development and Improvement</li>
          </ol>
        </div>
        `,
    website: "https://share-now.com",
    images: ["media/portfolio-images/monolith-to-micro.png"],
  },
];
