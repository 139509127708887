import * as constants from "../../constants";
import ContactForm from "../contact-form/contact-form";

export default function Modals() {
  return (
    <>
      {/* <!-- Contact me modal --> */}
      <div
        className="modal fade"
        id="contact-modal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="portfolio-modal"
      >
        <div className="modal-dialog modal-center" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="modal-title">
                <h2>
                  <span className="point">Let’s work together</span>
                </h2>
              </div>
              <div className="modal-description">
                <p>
                  Are you working on something great? I would love to help make
                  it happen! Drop me a letter and start your project right now!
                  Just do it.
                </p>
              </div>
              <div className="modal-form">
                <ContactForm footerClass="js-modal-form" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Contact me modal -->

      <!-- Thank you modal --> */}
      <div
        className="modal fade"
        id="thanks-modal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="thanks-modal"
      >
        <div className="modal-dialog modal-center" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="modal-title">
                <h2>
                  <span className="point">Your message has been sent</span>
                </h2>
              </div>
              <div className="modal-description">
                <p>
                  Thank you for your interest in my work. I’ll contact you just
                  in a few days. Stay tuned and see you soon, friend!
                </p>
              </div>
              <a href="#" className="site-btn" data-dismiss="modal">
                Back to cv
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Thank you modal -->

      <!-- Error message modal --> */}
      <div
        className="modal fade"
        id="error-modal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="error-modal"
      >
        <div className="modal-dialog modal-center" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="modal-title">
                <h2>
                  <span className="point">Ooops!</span>
                </h2>
              </div>
              <div className="modal-description">
                <p>Something go wrong!</p>
              </div>
              <a href="#" className="site-btn" data-dismiss="modal">
                Try again
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Error message modal --> */}
    </>
  );
}
