export const title =
  "Abdallah Albarmawi - Software Engineer - I enjoy developing solutions that enhance the lives of others";
export const metaTitle = title;
export const metaKeywords =
  " Software Engineer, software solution architect, Web Developer, software developer, micro services, aws solution architect, html, css, javascript, java, react, reactjs, typescript, aws, serverless";
export const metaDescription =
  " Software Engineer - I enjoy developing solutions that enhance the lives of others.";
export const altText = metaDescription;
export const copyRight = `Copyright ${new Date().getFullYear()} Abdallah Albarmawi.`;
export const metaImage =
  "https://www.abdallahalbarmawi.me/media/portfolio-images/solution-architect-abdallah-albarmawi.png";
