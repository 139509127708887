import "../styles/css/master.css";
import Head from "next/head";
import "../styles/scss/main.scss";
import * as constants from "../src/constants";

export default function RootLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <>
      <Head>
        <title>{constants.metaTitle}</title>
        <meta name="description" content={constants.metaDescription} />
        <meta name="title" content={constants.metaTitle} />
        <meta name="image" content={constants.metaImage} />
        <meta name="keywords" content={constants.metaKeywords} />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="canonical" href="https://www.abdallahalbarmawi.me/" />
        <meta http-equiv="content-type" content="text/html" />
        <meta name="robots" content="nofollow" />
        <meta name="revisit-after" content="10 days" />
        <link rel="apple-touch-icon" href="/apple-touch-icon.png" />

        <meta
          property="og:url"
          content="https://www.abdallahalbarmawi.me/"
        ></meta>
        <meta property="og:type" content="website" />
        <meta property="og:image" content={constants.metaImage} />
        <meta property="og:title" content={constants.metaTitle} />
        <meta property="og:description" content={constants.metaDescription} />
        <meta property="og:image:width" content="570" />
        <meta property="og:image:height" content="320" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="www.abdallahalbarmawi.me" />
        <meta
          property="twitter:url"
          content="https://www.abdallahalbarmawi.me/"
        />
        <meta name="twitter:title" content={constants.metaTitle} />
        <meta name="twitter:description" content={constants.metaDescription} />
        <meta name="twitter:image" content={constants.metaImage} />
      </Head>

      <main>{children}</main>

      <script src="/js/jquery-2.2.3.min.js" defer />
      <script src="/js/bootstrap.min.js" defer />
      <script src="/js/jquery.placeholder.min.js" defer />
      <script src="/js/mixitup-v3/dist/mixitup.min.js" defer />
      <script src="/js/wow.min.js" defer />
      <script src="/js/theme.js" defer />
    </>
  );
}
