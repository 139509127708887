export default function ContactForm({ footerClass }: { footerClass: string }) {
  return (
    <form action="/api/contact" method="post" className={footerClass}>
      <div className="row form-wrapper">
        <div className="col-lg-5 col-md-5">
          <div className="form-group">
            <input
              className="form-field js-field-name"
              type="text"
              placeholder="Name"
              required
            />
            <span className="form-validation"></span>
            <span className="form-invalid-icon">
              <i className="mdi mdi-close" aria-hidden="true"></i>
            </span>
          </div>
          <div className="form-group">
            <input
              className="form-field js-field-email"
              type="email"
              placeholder="E-mail"
              required
            />
            <span className="form-validation"></span>
            <span className="form-invalid-icon">
              <i className="mdi mdi-close" aria-hidden="true"></i>
            </span>
          </div>
        </div>
        <div className="col-lg-7 col-md-7">
          <div className="form-group">
            <textarea
              className="form-field js-field-message"
              placeholder="Message"
              required
            ></textarea>
            <span className="form-validation"></span>
          </div>
        </div>
        <div className="col-md-12">
          <div className="submit-holder">
            <input
              className="site-btn js-submit-contact"
              type="submit"
              value="Send message"
            />
            <a href="#" className="site-btn gray-btn" data-dismiss="modal">
              Back to cv
            </a>
          </div>
        </div>
      </div>
    </form>
  );
}
