import * as constants from "../../constants";

export default function Feedback() {
  return (
    <section id="feedback" className="section">
      <div className="container">
        <div className="row wave-bg">
          <div className="zigzag wow slideInLeft">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              version="1.1"
              x="0px"
              y="0px"
              viewBox="0 0 105 20"
              xmlSpace="preserve"
            >
              <g>
                <rect className="st0" width="105" height="20" />
                <g>
                  <polyline
                    className="st1"
                    points="2.5,5 16.8,15 31.1,5 45.3,15 59.6,5 73.9,15 88.2,5 102.5,15   "
                  />
                </g>
              </g>
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              version="1.1"
              x="0px"
              y="0px"
              viewBox="0 0 105 20"
              xmlSpace="preserve"
            >
              <g>
                <rect className="st0" width="105" height="20" />
                <g>
                  <polyline
                    className="st1"
                    points="2.5,5 16.8,15 31.1,5 45.3,15 59.6,5 73.9,15 88.2,5 102.5,15   "
                  />
                </g>
              </g>
            </svg>
          </div>
          <div className="col-md-4 wow slideInLeft">
            <div className="section-sidebar">
              <h2>
                <span className="point">Feedback</span>
              </h2>
              <div className="opacity-box">
                <p>
                  Take a look at the reviews of my customers and ensure the
                  quality of my services.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-8 right-box">
            <div className="row wow zoomIn">
              <div className="col-md-12">
                <div className="feedback-block">
                  <img
                    className="feedback-image"
                    src="/media/feedback-images/hg.jpeg"
                    alt={constants.altText}
                  />
                  <h4 className="about-tittle">Haitam Giat</h4>
                  <a
                    target="_blank"
                    href="https://www.linkedin.com/in/haitam-giat-02b92913/"
                    className="mdi fonts-icons mdi-linkedin feedback-icon"
                  ></a>
                  <p className="about-info">
                    Founder and CEO at Modarby.com | Ex-Microsoft
                  </p>
                  <div className="opacity-box">
                    <p>
                      “Very dedicated resource, open minded for new business
                      ideas, has strong programming background. I do recommend
                      for any freelance or full time job”
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="row wow zoomIn">
              <div className="col-md-12">
                <div className="feedback-block">
                  <img
                    className="feedback-image"
                    src="media/feedback-images/kk.jpeg"
                    alt={constants.altText}
                  />
                  <h4 className="about-tittle">Kent Kirschner</h4>
                  <a
                    target="_blank"
                    href="https://www.linkedin.com/in/kentkirschner/"
                    className="mdi fonts-icons mdi-linkedin feedback-icon"
                  ></a>
                  <p className="about-info">
                    Executive Director @ BeMotion Inc
                  </p>
                  <div className="opacity-box">
                    <p>
                      “He will always try to do what is required, even if it
                      means performing tasks that are not in the job description
                      or if required to do extra work unexpectedly. However, may
                      sometimes complain about the extra load. Aiming for a top
                      job in the organization. He sets very high standards,
                      aware that this will bring attention and promotion.”
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="row wow zoomIn">
              <div className="col-md-12">
                <div className="feedback-block">
                  <img
                    className="feedback-image"
                    src="media/feedback-images/mt.jpeg"
                    alt={constants.altText}
                  />
                  <h4 className="about-tittle">Mohammad Taher</h4>
                  <a
                    target="_blank"
                    href="https://www.linkedin.com/in/mohammad-taher/"
                    className="mdi fonts-icons mdi-linkedin feedback-icon"
                  ></a>
                  <p className="about-info">Software Engineering Manager</p>
                  <div className="opacity-box">
                    <p>
                      “When working to a firm but realistic deadline, Abdallah
                      always completes tasks to a high standard. Care and
                      accuracy is obvious even when put under pressure of time.
                      Prepared to work all the extra hours it takes to meet the
                      deadline.”
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
