import * as constants from "../../constants";

export default function Footer() {
  return (
    <footer className="footer text-center">
      <div className="container">
        <div className="row">
          <div className="col-md-12 wow zoomIn">
            <div className="copyright">
              <p>{constants.copyRight}</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
