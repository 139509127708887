/* eslint-disable @next/next/no-img-element */

import * as constants from "../../constants";
import Contact from "../contact/contact";
import Education from "../education/education";
import Experience from "../experience/experience";
import Feedback from "../feedback/feedback";
import Footer from "../footer/footer";
import Header from "../header/header";
import Hello from "../hello/hello";
import Modals from "../modal/modals";
import Portfolio from "../portfolio/portfolio";
import Skills from "../skills/skills";

export default function Home() {
  return (
    <div>
      <Header />
      <Hello />
      <Skills />
      <Experience />
      <Education />
      <Portfolio />
      <Feedback />
      <Contact />
      <Footer />
      <Modals />
    </div>
  );
}
