/* eslint-disable @next/next/no-img-element */
import parse from "html-react-parser";
import * as constants from "../../constants";
import { categories, projects } from "./config";

export default function Portfolio() {
  const categoriesLi = categories.map((cat) => {
    return (
      <li
        key={cat.key}
        className={"colored-link"}
        data-mixitup-control
        data-filter={cat.key}
      >
        {cat.title}
      </li>
    );
  });
  const projectsDev = projects.map((proj) => {
    const className = `col-md-3 col-sm-6 col-xs-6 mix ${proj.categories} wow flipInX`;
    return (
      <div key={proj.key} className={className}>
        <a
          data-toggle="modal"
          data-target="#portfolio-modal"
          data-name={proj.key}
          title={proj.title}
          href="#"
          className="portfolio-box"
        >
          <div className="portfolio-img">
            <img src={proj.img} alt={constants.altText} />
          </div>
          <div className="portfolio-name">
            <span>{proj.title}</span>
          </div>
          <div className="portfolio-date">
            <span>{proj.date}</span>
          </div>
        </a>
      </div>
    );
  });

  const modalsBody = projects.map((proj) => {
    return (
      <div key={proj.key} className="modal-body" data-name={proj.key}>
        <div className="modal-title">
          <h2>
            <span className="point">{proj.title}</span>
          </h2>
        </div>
        <div className="modal-description">
          <div>{parse(proj.description)}</div>
        </div>
        <div className="about-me-info">
          <p>
            <span className="span-title">Stack</span>
            <span>{proj.stack}</span>
          </p>
          <p>
            <span className="span-title">Date</span>
            <span>{proj.date}</span>
          </p>
        </div>
        <div className="modal-images">
          {proj.images.map((img, ix) => (
            <img key={ix} src={img} alt={constants.altText} />
          ))}
          {/* <img
            src="media/modal-images/modal-image-1.jpg"
            alt={constants.altText}
          />
          <img
            src="media/modal-images/modal-image-2.jpg"
            alt={constants.altText}
          /> */}
        </div>
        <div className="about-btns">
          <a target="_blank" href={proj.website} className="site-btn">
            VIsit Website
          </a>
          <a href="#" className="site-btn gray-btn" data-dismiss="modal">
            Back to cv
          </a>
        </div>
      </div>
    );
  });

  return (
    <section id="portfolio" className="section section-small-padding">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="section-title wow slideInLeft">
              <h2>
                <span className="point">Portfolio</span>
              </h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="portfolio-sort wow slideInLeft">
              <ul className="list-inline">{categoriesLi}</ul>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="portfolio">
              <div className="row">{projectsDev}</div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="portfolio-modal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="portfolio-modal"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">{modalsBody}</div>
        </div>
      </div>
    </section>
  );
}
