export default function Experience() {
  return (
    <section id="experience" className="section">
      <div className="container">
        <div className="row wave-bg">
          <div className="zigzag wow slideInLeft">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              version="1.1"
              x="0px"
              y="0px"
              viewBox="0 0 105 20"
              xmlSpace="preserve"
            >
              <g>
                <rect className="st0" width="105" height="20" />
                <g>
                  <polyline
                    className="st1"
                    points="2.5,5 16.8,15 31.1,5 45.3,15 59.6,5 73.9,15 88.2,5 102.5,15   "
                  />
                </g>
              </g>
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              version="1.1"
              x="0px"
              y="0px"
              viewBox="0 0 105 20"
              xmlSpace="preserve"
            >
              <g>
                <rect className="st0" width="105" height="20" />
                <g>
                  <polyline
                    className="st1"
                    points="2.5,5 16.8,15 31.1,5 45.3,15 59.6,5 73.9,15 88.2,5 102.5,15   "
                  />
                </g>
              </g>
            </svg>
          </div>
          <div className="col-md-4 wow slideInLeft">
            <div className="section-sidebar">
              <h2>
                <span className="point">Experience</span>
              </h2>
              <div className="opacity-box">
                <p>
                  I partner with startups, organizations and Fortune 500
                  companies to build digital products that help clients overcome
                  challenges and create lasting connections with millions of
                  people every day.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-8 right-box">
            <div className="row wow zoomIn">
              <div className="col-md-12">
                <div className="about-row">
                  <h4 className="about-tittle">Staff Software Engineer</h4>
                  <p className="about-info">
                    <a target="_blank" href="https://www.yelp.com/">
                      Yelp
                    </a>{" "}
                  </p>
                  <p>March, 2021 — Present</p>
                  <div className="opacity-box">
                    <p>
                      Led cross-functional teams in the development of Ads units
                      for mobile apps.
                    </p>
                    <p>
                      Acted as the primary point of contact for stakeholders,
                      gathering requirements, providing updates, and addressing
                      concerns.
                    </p>
                    <p>
                      Designed project roadmaps and task schedules, effectively
                      allocating resources and managing project timelines.
                    </p>
                    <p>Onboard & mentor new engineers.</p>
                    <p>Participate in the interview and hiring process.</p>
                  </div>
                </div>
              </div>
            </div>
            <hr />

            <div className="row wow zoomIn">
              <div className="col-md-12">
                <div className="about-row">
                  <h4 className="about-tittle">Solution Architect</h4>
                  <p className="about-info">
                    <a target="_blank" href="https://www.receiptwallet.app/">
                      Receipt Wallet
                    </a>{" "}
                  </p>
                  <p>May, 2021 — Present</p>
                  <div className="opacity-box">
                    <p>
                      Designing and creating the overall structure and framework
                      of Receipt Wallet to meet business requirements, ensuring
                      scalability, security, and efficiency.
                    </p>
                    <p>
                      Leading a cross-functional team on a variety of
                      development projects and delivering solutions to meet and
                      exceed client&apos;s briefs.
                    </p>
                    <p>
                      Discuss and analysis the requirements with different
                      stakeholders.
                    </p>
                    <p>Define the system roadmap and put priorities.</p>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="row wow zoomIn">
              <div className="col-md-12">
                <div className="about-row">
                  <h4 className="about-tittle">Senior Software Engineer</h4>
                  <p className="about-info">
                    <a target="_blank" href="https://www.share-now.com">
                      Share Now
                    </a>{" "}
                  </p>
                  <p>May, 2018 — Feb, 2021</p>
                  <div className="opacity-box">
                    <p>
                      Leading a cross-functional team on a mission of migrating
                      the backend system from monolith into microservices
                      architecture.
                    </p>
                    <p>
                      Participate in the overall system architecture, and best
                      practices.
                    </p>
                    <p>
                      Discuss and analysis the requirements with different
                      stakeholders.
                    </p>
                    <p>Define the system roadmap and put priorities.</p>
                    <p>Onboard & mentor new engineers.</p>
                    <p>Participate in the interview and hiring process.</p>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="row wow zoomIn">
              <div className="col-md-12">
                <div className="about-row">
                  <h4 className="about-tittle">
                    Bitcoin Payment Gateway Solution Architect
                  </h4>
                  <p className="about-info">Eden Money</p>
                  <p>Feb, 2019 — May, 2021</p>
                  <div className="opacity-box">
                    <p>
                      Leading a cross functional team on a mission of building a
                      payment gateway for Bitcoin, that enables people to
                      exchange their fiat currencies with Bitcoin.{" "}
                    </p>
                    <p>
                      Participate in the overall system architecture, and best
                      practices.
                    </p>
                    <p>
                      Discuss and analysis the requirements with different
                      stakeholders
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
